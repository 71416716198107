export interface PromiseCallbacks<T = void> {
    promise: Promise<T>;
    resolve: (data: T) => void;
    reject: (error: Error) => void;
}

export function getPromiseCallbacks<T = void>(): PromiseCallbacks<T> {
    const callback: PromiseCallbacks<T> = {} as PromiseCallbacks<T>;

    callback.promise = new Promise<T>((resolve: (data: T) => void, reject: (error: Error) => void) => {
        callback.resolve = resolve;
        callback.reject = reject;
    });

    return callback;
}