import type { AsyncFunction } from '@egr/xbox/utils/Types';

import { getEnvVar } from '@egr/xbox/utils/ReactScriptHelper';

const hooks: Array<AsyncFunction<string, [string]>> = [];
export function addGatekeeperIdHook(callback: AsyncFunction<string, [string]>): VoidFunction {
    hooks.push(callback);
    return () => {
        const index = hooks.indexOf(callback);
        if (index !== -1) {
            hooks.splice(index, 1);
        }
    };
}

export async function getGatekeeperId(): Promise<string> {
    let id = getEnvVar('GATEKEEPER_ID', '');

    for (const hook of hooks) {
        // fallback to the previews id if `undefined` is returned
        // Note: keep this check even if it `undefined` is not allowed based on
        // the type definition of the hook function
        id = (await hook(id)) ?? id;
    }

    return id;
}