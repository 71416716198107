import { ios } from '@egr/xbox/utils/UserAgent';

/**
 * Selects the text of an html-Input-Element
 * inputElement.select() work in most browsers, but not on IOS Safari
 * @param inputElement
 */
export function selectInputText(inputElement: HTMLInputElement | HTMLTextAreaElement | undefined, wait?: boolean): void {
    if (inputElement === undefined) {
        return;
    }
    if (wait === true) {
        window.setTimeout(() => { selectInputText(inputElement); }, 100);
    } else if (ios) {
        if (inputElement.type === 'number') {
            return;
        }

        inputElement.setSelectionRange(0, inputElement.value.length);
    } else {
        inputElement.select();
    }
}

export function preventEvent(event: React.SyntheticEvent<HTMLElement>): void {
    event.preventDefault();
}

export function *getEnabledOptions<T extends {[P in keyof T]: boolean | undefined | null}>(data: T): IterableIterator<keyof T> {
    for (const [key, value] of Object.entries(data)) {
        if (value === true) {
            yield key as keyof T;
        }
    }
}

export async function elementHasSize(element: HTMLElement | undefined, treshold: number = 0, timeout?: number): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
        if (element == null) {
            return resolve(false);
        }

        if (window.ResizeObserver) {
            const observer = new window.ResizeObserver(
                (entries) =>  {
                    for (const entry of entries) {
                        if (
                            (entry.target?.clientWidth ?? 0) > treshold
                        ) {
                            observer.disconnect();
                            resolve(true);
                        }
                    }
                }
            );

            observer.observe(element);

            if (timeout != null) {
                window.setTimeout(
                    () => {
                        observer.disconnect();
                        resolve(false);
                    },
                    timeout
                );
            }
        } else {
            // IE fallback
            const checker: number = window.setInterval(
                () => {
                    if (element.clientWidth > treshold) {
                        clearInterval(checker);
                        resolve(true);
                    }
                },
                100
            );

            if (timeout != null) {
                window.setTimeout(
                    () => {
                        clearInterval(checker);
                        resolve(false);
                    },
                    timeout
                );
            }
        }
    });
}