import type { StackFrame } from '@sentry/browser';

import { stripQueryString, stripTrailingSlash } from '../../Url';

import { appInfo } from '@egr/xbox/app-api/AppInfo';

export function fixFrame(frame: StackFrame): StackFrame {
    if (appInfo?.app_caching?.active === true) {
        // Sentry should use the original src url for fetching the `.map` files
        const appScheme: string = stripTrailingSlash(appInfo.app_caching.app_scheme);
        const appSchemeWithoutProtocol: string = appScheme.replace('pcon:', '');
        const initializationUrl: string = stripTrailingSlash(stripQueryString(appInfo.initialization_url));

        if (frame?.filename != null) {
            frame.filename = frame.filename.replace(appScheme, initializationUrl);
            frame.filename = frame.filename.replace(appSchemeWithoutProtocol, initializationUrl);
            frame.filename = frame.filename.replace(/^\:/, '');
        }
    }

    return frame;
}