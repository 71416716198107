import type { InitOptions, i18n } from 'i18next';

import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import * as Lockr from 'lockr';
import { initReactI18next } from 'react-i18next';

import { trackEvent } from '@egr/xbox/telemetry/MatomoUtils';
import { getEnvVar, getEnvArray } from '@egr/xbox/utils/ReactScriptHelper';

import { isNotNullOrEmpty, isString } from '@easterngraphics/wcf/modules/utils/string';

export let USER_LANGUAGES: Array<string> = getEnvArray('USER_LANGUAGES').filter(isNotNullOrEmpty);
const FALLBACK_LANGUAGE: string = getEnvVar('FALLBACK_LANGUAGE', '');

export async function initInstance(
    whitelist: Array<string> = USER_LANGUAGES,
    lng?: string,
): Promise<i18n> {
    USER_LANGUAGES = whitelist;

    const instance = i18next.use(Backend).use(LanguageDetector).use(initReactI18next);

    const initialLanguage = lng ?? Lockr.get<{ userLanguage: string | undefined }>('app-settings')?.userLanguage;

    const initOptions: InitOptions = {
        fallbackLng: 'en',
        returnEmptyString: false,
        debug: isNotNullOrEmpty(getEnvVar('DEBUG_I18NEXT', '')),
        supportedLngs: USER_LANGUAGES,
        ns: ['translation'],
        load: 'languageOnly',
        defaultNS: 'translation',
        keySeparator: '::',
        nsSeparator: '__SS',
        backend: {
            loadPath: './locales/{{lng}}/{{ns}}.json',
        },
        react: {
            bindI18n: 'languageChanged loaded',
            useSuspense: false,
        },
        detection: {
            // disables saving of language in localStorage
            caches: [],
        },
        compatibilityJSON: 'v3',
        lng: initialLanguage ?? isString(initialLanguage) ? initialLanguage : undefined,
    };

    await instance.init(initOptions);
    return instance;
}

export async function setLanguage(language: string): Promise<boolean> {
    if (USER_LANGUAGES.indexOf(language) !== -1) {
        await i18next.changeLanguage(language);
    } else if (isNotNullOrEmpty(FALLBACK_LANGUAGE)) {
        await i18next.changeLanguage(FALLBACK_LANGUAGE);
    } else {
        return false;
    }

    trackEvent({
        category: 'settings',
        action: 'switch',
        name: language
    });

    return true;
}