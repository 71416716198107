import { getEnvNumber, getEnvVar, getEnvBoolean } from '@egr/xbox/utils/ReactScriptHelper';

export const MATOMO_TRACKING_ID: string = getEnvVar('MATOMO_TRACKING_ID', '');
export const MATOMO_TRACKING_URL: string = getEnvVar('MATOMO_TRACKING_URL', '');

export const telemetryPossible = (MATOMO_TRACKING_ID !== '' && MATOMO_TRACKING_URL !== '');

export const MATOMO_DIMENSION_APP: number | undefined = getEnvNumber('MATOMO_DIMENSION_APP');
export const MATOMO_DIMENSION_LOGIN: number | undefined = getEnvNumber('MATOMO_DIMENSION_LOGIN');
export const MATOMO_DIMENSION_ORIENTATION: number | undefined = getEnvNumber('MATOMO_DIMENSION_ORIENTATION');
export const MATOMO_VISIT_DIMENSION_LOGIN: number | undefined = getEnvNumber('MATOMO_VISIT_DIMENSION_LOGIN');
export const MATOMO_VISIT_DIMENSION_APP: number | undefined = getEnvNumber('MATOMO_VISIT_DIMENSION_APP');
export const MATOMO_FULLSCREEN_DIMENSION: number | undefined = getEnvNumber('MATOMO_FULLSCREEN_DIMENSION');
export const MATOMO_LAYOUT_DIMENSION: number | undefined = getEnvNumber('MATOMO_LAYOUT_DIMENSION');

export const DEBUG_MATOMO_TRACKING: boolean = getEnvBoolean('DEBUG_MATOMO_TRACKING');

export const MATOMO_TRACK_LAYER_CHANGE: boolean = getEnvBoolean('MATOMO_TRACK_LAYER_CHANGE');

/**
 * Source: https://developer.matomo.org/guides/tracking-javascript-guide#customise-the-type-of-files-tracked-as-downloaded
 */
export const DefaultDownloadExtensions = new Set<string>([
    '7z', 'aac', 'arc', 'arj', 'apk', 'asf', 'asx', 'avi', 'bin', 'bz', 'bz2', 'csv', 'deb', 'dmg', 'doc',
    'exe', 'flv', 'gif', 'gz', 'gzip', 'hqx', 'jar', 'jpg', 'jpeg', 'js', 'mp2', 'mp3', 'mp4', 'mpg',
    'mpeg', 'mov', 'movie', 'msi', 'msp', 'odb', 'odf', 'odg', 'odp', 'ods', 'odt', 'ogg', 'ogv',
    'pdf', 'phps', 'png', 'ppt', 'qt', 'qtm', 'ra', 'ram', 'rar', 'rpm', 'sea', 'sit', 'tar',
    'tbz', 'tbz2', 'tgz', 'torrent', 'txt', 'wav', 'wma', 'wmv', 'wpd', '', 'xls', 'xml', 'z', 'zip'
]);

export const AdditionalDownloadExtensions = new Set<string>([
    'docx', 'xlsx', 'dwg', 'dxf', 'sat', 'sab', '3ds', 'skp', 'fml', 'env', 'obk', 'matz', 'tif',
    'bmp', 'pcx', 'tga', 'ai', 'gdl', 'ogrp', 'sif', 'rstyle', 'adsk', 'rfa', 'ldt', 'ies', 'rolf',
    'fbx', 'obj', 'mtl', 'pec', 'o3d', 'o3dtgz'
]);