import type { LockContext } from '@easterngraphics/wcf/modules/utils/async';
import type { BaseApp, OpenSessionEventOptions } from '@egr/xbox/base-app/App';

import isEqual from 'lodash/isEqual';

import { getGatekeeperId } from '../hooks/GatekeeperId';

import { executeCallbacks, getTaskQueue, SessionManager } from './SessionManager';

import { openSession, type RequestOptions } from '@egr/gatekeeper-lib';
import { NetworkError } from '@egr/gatekeeper-lib/lib/errors';
import { methodWithDebugTimer } from '@egr/xbox/utils/Debug';
import { limitConcurrentCalls } from '@egr/xbox/utils/Promise';
import { getEnvBoolean, getEnvVar } from '@egr/xbox/utils/ReactScriptHelper';
import { getLocale } from '@egr/xbox/utils/String';
import { captureException } from '@egr/xbox/utils/errors/Error';

export type GatekeeperSessionOptions = RequestOptions;

interface GatekeeperSessionResponse {
    server: string;
    sessionId: string;
    keepAliveInterval: number;
}

/**
 * @param updatedSessionOptions If this value is set, the specified options are sent to the gatekeeper instead of the original ones used for the initial request
 */
export type GatekeeperRequestRetryCallback = (updatedSessionOptions?: GatekeeperSessionOptions) => void;

/**
 * This function can be used to handle application specific errors in the communication with the gatekeeper.
 * (e.g. if a pCon.login access token expires, the token can be renewed and than the request can be retried via the
 * callback provided to this function)
 *
 * @param error the error hat occurred when requesting a new session
 * @param retry a callback with which the original request can be executed again
 * @param reject the reject function of the original promise return to the function which requested the new session
 *
 * @returns true if the error was handled and false otherwise
 */
type RequestErrorHandler = (error: Error, retry: GatekeeperRequestRetryCallback, reject: (error: Error) => void) => Promise<boolean>;

const MAX_SESSION_AGE: number = 14400000; // 4h
const REUSE_EAIWS_SESSIONS: boolean = getEnvBoolean('REUSE_EAIWS_SESSIONS', true);

export class GatekeeperManager<App extends BaseApp = BaseApp> extends SessionManager<App> {
    public readonly requestErrorHandlers: Array<RequestErrorHandler> = [];

    protected sessionCreationTime: undefined | number;
    protected sessionReusabilityOptions: Record<string, unknown> | undefined;

    public constructor(
        app: App,
        protected sessionOptionsProvider?: () => Promise<GatekeeperSessionOptions>,
        protected openSessionCallback: typeof openSession = openSession
    ) {
        super(app);
    }

    public async preInitializeEaiwsSession(primaryDataLanguage?: string): Promise<void> {
        if (REUSE_EAIWS_SESSIONS) {
            const lockContext: LockContext = await this.getSessionLockContext();
            try {
                await this.startEaiwsSession(primaryDataLanguage);
                await this.setLanguage(primaryDataLanguage);
                void this.app.eaiwsSession.session.configureSessionLog(true);
                if (this.app.eaiwsSession.isValid) {
                    const {queue: postConnectQueue, callbacks: callbacksPostConnectQueue} = getTaskQueue<[OpenSessionEventOptions]>('preInitializeEaiwsSession.postConnect');

                    this.app.events.session.trigger({
                        type: 'pre-initialization',
                        tasks: {
                            postConnect: postConnectQueue
                        },
                        primaryDataLanguage
                    });

                    await executeCallbacks('preInitializeEaiwsSession', 'postConnect', callbacksPostConnectQueue, [{}]);
                }
            } finally {
                lockContext.unlock();
            }
        }
    }

    @limitConcurrentCalls()
    protected async startEaiwsSession(primaryDataLanguage?: string): Promise<void> {
        const gatekeeperSessionOptions: GatekeeperSessionOptions = (await this.sessionOptionsProvider?.()) ?? {};
        let requestNewSession: boolean = !(await this.shouldReuseSession(primaryDataLanguage, gatekeeperSessionOptions));

        // Note: since the servers also need to be maintained, we should not use a session for a too long period of time.
        // Therefore we rather ask for a new session to give the gatekeeper the possibility to send us to another server.
        if (!requestNewSession && Date.now() > ((this.sessionCreationTime ?? 0) + MAX_SESSION_AGE)) {
            requestNewSession = true;
        }

        if (requestNewSession) {
            const [response, usedSessionOptions] = await this.requestSession(primaryDataLanguage, gatekeeperSessionOptions);
            this.sessionCreationTime = Date.now();

            this.app.eaiwsSession.connect(
                response.server.slice(0, -1), // remove trailing slash
                response.sessionId,
                response.keepAliveInterval * 1000 // the keepAliveInterval is given in seconds
            );

            this.sessionReusabilityOptions = await this.getSessionReusabilityOptions(primaryDataLanguage, usedSessionOptions ?? {});
        }
    }

    protected override async endEaiwsSession(): Promise<void> {
        if (REUSE_EAIWS_SESSIONS) {
            if (this.app.eaiwsSession.isValid) {
                try {
                    await this.app.eaiwsSession.session.loadEmptySession();
                } catch (error) {
                    this.app.eaiwsSession.disconnect();
                }
            }
        } else {
            return super.endEaiwsSession();
        }
    }

    @methodWithDebugTimer('gatekeeper request')
    protected async requestSession(
        primaryDataLanguage?: string,
        gatekeeperSessionOptions?: GatekeeperSessionOptions
    ): Promise<[GatekeeperSessionResponse, GatekeeperSessionOptions | undefined]> {
        return new Promise<[GatekeeperSessionResponse, GatekeeperSessionOptions | undefined]>((
            resolve: (response: [GatekeeperSessionResponse, GatekeeperSessionOptions | undefined]) => void,
            reject: (error: Error) => void
        ): void => {
            const request: GatekeeperRequestRetryCallback = async (updatedSessionOptions?: GatekeeperSessionOptions): Promise<void> => {
                try {
                    const sessionOptions: GatekeeperSessionOptions | undefined = updatedSessionOptions ?? gatekeeperSessionOptions;
                    const reply: GatekeeperSessionResponse = await this.openSessionCallback(
                        await getGatekeeperId(),
                        {
                            locale: getLocale(primaryDataLanguage),
                            applicationId: getEnvVar('GATEKEEPER_APPLICATION_ID'),
                            ...sessionOptions
                        }
                    );
                    resolve([reply, sessionOptions]);
                } catch (error) {
                    if (error instanceof NetworkError) {
                        this.app.handleNetworkError(request);
                    } else {
                        for (const errorHandler of this.requestErrorHandlers) {
                            try {
                                const handled: boolean = await errorHandler(error, request, reject);
                                if (handled) {
                                    return;
                                }
                            } catch (handlerError) {
                                captureException(handlerError, 'debug');
                                reject(handlerError);
                                return;
                            }
                        }
                        reject(error);
                    }
                }
            };

            request();
        });
    }

    // Note: the `primaryDataLanguage` argument should be required
    protected async shouldReuseSession(primaryDataLanguage: string | undefined, gatekeeperSessionOptions: GatekeeperSessionOptions): Promise<boolean> {
        if (!REUSE_EAIWS_SESSIONS || !this.app.eaiwsSession.isValid || this.sessionReusabilityOptions == null) {
            return false;
        }

        return isEqual(
            this.sessionReusabilityOptions,
            await this.getSessionReusabilityOptions(primaryDataLanguage, gatekeeperSessionOptions)
        );
    }

    // Note: the `primaryDataLanguage` argument should be required
    protected async getSessionReusabilityOptions(
        primaryDataLanguage: string | undefined,
        gatekeeperSessionOptions: GatekeeperSessionOptions
    ): Promise<Record<string, unknown> | undefined> {
        if (!REUSE_EAIWS_SESSIONS) {
            return undefined;
        }

        return {
            gatekeeperId: await getGatekeeperId(),
            gatekeeperSessionOptions: gatekeeperSessionOptions,
            primaryDataLanguage
        };
    }
}
