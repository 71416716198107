import type { BaseApp } from '../App';
import type { OpenSessionOptions } from '@easterngraphics/wcf/modules/eaiws';

import { SessionManager } from './SessionManager';

import { EAIWS_SERVER } from '@egr/xbox/utils/Constants';
import { getEnvVar } from '@egr/xbox/utils/ReactScriptHelper';

import { isNotNullOrEmpty } from '@easterngraphics/wcf/modules/utils/string';

const EaiwsStartup: string = getEnvVar('EAIWS_STARTUP', '');
const EaiwsKeepaliveInterval: number = parseInt(getEnvVar('EAIWS_KEEPALIVE_INTERVAL', '60'), 10);
const SessionRetry: number = parseInt(getEnvVar('EAIWS_SESSION_OPEN_RETRY', '0'), 0);

export function hasArgumentsRequiredForDirectEaiwsAccess(): boolean {
    return isNotNullOrEmpty(EAIWS_SERVER) && isNotNullOrEmpty(EaiwsStartup);
}

export class StaticEaiwsManager extends SessionManager {
    public constructor(
        app: BaseApp,
        protected packageGroups?: Array<string>
    ) {
        super(app);

        if (!hasArgumentsRequiredForDirectEaiwsAccess()) {
            throw new Error('Incorrect eaiws setup');
        }
    }

    // Note: can be used if a second session is required
    public getSessionOpenOptions(): [string, OpenSessionOptions] {
        return [
            EAIWS_SERVER,
            {
                startup: EaiwsStartup,
                packageGroups: this.packageGroups != null ? this.packageGroups.join(',') : undefined,
                keepAliveInterval: EaiwsKeepaliveInterval * 1000,
            },
        ];
    }

    protected async startEaiwsSession(): Promise<void> {
        // FixMe: handle return value
        let sessionOpened = false;
        let retryCount = 0;
        try {
            await this.app.eaiwsSession.open(...this.getSessionOpenOptions());
        } catch (error) {
            if (retryCount === SessionRetry) {
                throw error;
            }
            while (!sessionOpened && retryCount < SessionRetry) {
                try {
                    sessionOpened = await this.app.eaiwsSession.open(...this.getSessionOpenOptions());
                } catch {
                    //
                } finally {
                    await new Promise(resolve => setTimeout(resolve, 500));
                    retryCount++;
                }
            }
        }
    }
}
