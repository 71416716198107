const globalValueCache = new WeakMap<object, unknown>();

/**
 * This function can be used to cache values between function calls without the
 * need to pollute the surrounding scope.
 *
 * @param key The object which used as cache key. Note: `this.someFunctionName` should work in most cases.
 * @param initialValue The default value or a callback which returns the default value of the cache.
 * @returns The current cache state.
 */
export function getCachedValue<T extends {}>(key: object, initialValue: T | (() => T)): T {
    const value: unknown = globalValueCache.get(key);
    if (value !== undefined) {
        return value as T;
    }

    if (initialValue instanceof Function) {
        globalValueCache.set(key, initialValue());
    } else {
        globalValueCache.set(key, initialValue);
    }

    return globalValueCache.get(key) as T;
}