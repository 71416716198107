import { isNullOrEmpty, isNotNullOrEmpty } from '@easterngraphics/wcf/modules/utils/string';

export function formatSizeUnits(bytes: number, fractionDigits: number = 2): string {
    if  (bytes === 0) {
        return '0 Bytes';
    }
    const base: number = 1024;
    const texts: ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const f: number = Math.floor(Math.log(bytes) / Math.log(base));
    return `${parseFloat((bytes / Math.pow(base, f)).toFixed(fractionDigits))} ${texts[f]}`;
}

export function multilineTextToArray(
    input: string | undefined,
    keepEmptyLines?: boolean,
    trimLines?: boolean,
    removeTrailingEmptyLines?: boolean
): Array<string> {
    if (isNullOrEmpty(input)) {
        return [];
    }

    const temp: string = input.replace(/\r\n/g, '\n').replace(/\r/g, '\n');
    const lines: Array<string> = temp.split('\n').filter((value: string) => {
        if (keepEmptyLines) {
            return true;
        }
        return (value != null && value !== '');
    });

    if (trimLines) {
        return lines.map(trim);
    }

    if (removeTrailingEmptyLines) {
        for (let i: number = lines.length - 1; i >= 0; i--) {
            lines.length = i + 1;
            if (isNotNullOrEmpty(lines[i])) {
                break;
            }
        }
    }

    return lines;
}

function trim(value: string): string {
    return value.trim();
}

export function getValidString(value: string | undefined, fallback: string | undefined): string {
    if (isNotNullOrEmpty(value)) {
        return value;
    }
    if (isNotNullOrEmpty(fallback)) {
        return fallback;
    }
    return '';
}

export function stringReducer(previousValue: string | undefined, currentValue: string | undefined): string | undefined {
    if (isNotNullOrEmpty(currentValue)) {
        if (isNullOrEmpty(previousValue)) {
            return currentValue;
        }
        return `${previousValue} | ${currentValue}`;
    }
    return previousValue;
}

export const enum CurrencyStyle {
    DEFAULT = 'default',
    INFRONT = 'infront',
    BEHIND = 'behind',
    HIDDEN = 'hidden'
}

let defaultCurrencyStyle: CurrencyStyle = CurrencyStyle.DEFAULT;

export function setDefaultCurrencyStyle(value: CurrencyStyle): void {
    defaultCurrencyStyle = value;
}

export function getFormatedPrice(
    value: number | undefined,
    currency: string,
    language: string = 'de',
    currencyDisplay: 'symbol' | 'name' | 'code' = 'symbol',
    currencyStyle?: CurrencyStyle,
    maximumSignificantDigits?: number
): string {
    if (value == null || isNaN(value) || isNullOrEmpty(currency)) {
        return '';
    }

    if (Intl === undefined) {
        return `${value} ${currency}`;
    }

    const justDecimals: string | number = new Intl.NumberFormat(
        language,
        { style: 'currency', currency, currencyDisplay: 'code', maximumSignificantDigits }
    ).format(value).replace(currency, '').trim().replace(/\s+/g, ' ');

    switch (currencyStyle ?? defaultCurrencyStyle) {
        case CurrencyStyle.BEHIND:
            return `${justDecimals} ${currency}`;
        case CurrencyStyle.INFRONT:
            return `${currency} ${justDecimals}`;
        case CurrencyStyle.HIDDEN:
            return justDecimals;
        default:
            return new Intl.NumberFormat(
                language,
                { style: 'currency', currency, currencyDisplay, maximumSignificantDigits }
            ).format(value).replace(/\s+/g, ' ');
    }
}