import type { SupportedTrackingEvents } from './TrackingEvents';
import type { IObservableValue} from 'mobx';

import { observable, runInAction } from 'mobx';

import { captureException } from '../utils/errors/Error';

import {
    DEBUG_MATOMO_TRACKING,
    MATOMO_VISIT_DIMENSION_APP, MATOMO_DIMENSION_APP,
    MATOMO_TRACKING_URL, MATOMO_TRACKING_ID,
    DefaultDownloadExtensions, AdditionalDownloadExtensions,
    MATOMO_DIMENSION_ORIENTATION, MATOMO_LAYOUT_DIMENSION, MATOMO_FULLSCREEN_DIMENSION,
    MATOMO_DIMENSION_LOGIN, MATOMO_VISIT_DIMENSION_LOGIN, MATOMO_TRACK_LAYER_CHANGE
} from './TrackingConstants';

import { appInfo } from '@egr/xbox/app-api/AppInfo';
import { assert } from '@egr/xbox/utils/Debug';
import { isNotNullOrUndefined, loadScript } from '@egr/xbox/utils/Helper';
import { notNull } from '@egr/xbox/utils/Types';
import { stripHash } from '@egr/xbox/utils/Url';
import { addBreadcrumb } from '@egr/xbox/utils/errors/Breadcrumb';
import { Logger } from '@egr/xbox/utils/errors/Logger';

import { isNullOrEmpty, isNotNullOrEmpty } from '@easterngraphics/wcf/modules/utils/string';

function pushEventData(value: Array<unknown>): void {
    value = value.filter(isNotNullOrUndefined);

    if (DEBUG_MATOMO_TRACKING) {
        Logger.log(value);
    }

    try {
        addBreadcrumb({
            category: 'telemetry',
            message: JSON.stringify(value)
        });

        if (
            typeof window._paq !== 'undefined' &&
            (
                // cache only the first 100 events before the tracking has to be enabled
                // (the .length propery is undefined after the tracking was enabled)
                window._paq.length == null ||
                window._paq.length < 100
            )
        ) {
            window._paq.push(value);
        }
    } catch (error) {
        captureException(error, 'debug');
    }
}

declare global {
    interface Window {
        _paq?: Array<Array<unknown>> | {push: (data: Array<unknown>) => void, length: null};
    }
}

if (window._paq == null) {
    window._paq = [];
}

export function setCustomDimension(dimension: number | undefined, value: string): void {
    if (dimension != null) {
        pushEventData(['setCustomDimension', dimension, value]);
    }
}

export const matomoActive: IObservableValue<boolean> = observable.box(false);

export function disableTracking(): void {
    pushEventData(['forgetUserOptOut']);
    runInAction((): void => {
        matomoActive.set(false);
    });
}

// Note: cookies will be disabled if the `visitorId` is padded into this function
export async function enableTracking(visitorId?: string, startView?: string): Promise<void> {
    if (isNotNullOrEmpty(visitorId)) {
        pushEventData(['disableCookies']);
        pushEventData(['setVisitorId', visitorId]);
    }

    // set the dimensions before the first page hit is tracked
    updateOrientation();

    setCustomDimension(MATOMO_DIMENSION_APP, appInfo != null ? 'app' : 'web');
    setCustomDimension(MATOMO_VISIT_DIMENSION_APP, appInfo != null ? 'app' : 'web');
    setDownloadExtensions(new Set([...DefaultDownloadExtensions, ...AdditionalDownloadExtensions]));

    updateCustomUrl(startView);

    pushEventData(['enableLinkTracking']);

    if (MATOMO_TRACKING_ID !== '' && MATOMO_TRACKING_URL !== '') {
        pushEventData(['setTrackerUrl', MATOMO_TRACKING_URL + 'matomo.php']);
        pushEventData(['setSiteId', MATOMO_TRACKING_ID]);
        pushEventData(['forgetUserOptOut']);

        assert(window._paq!.length != null, '_paq should be an array');
        try {
            await loadScript(
                MATOMO_TRACKING_URL + 'matomo.js',
                true
            );
            assert(window._paq!.length == null, '_paq should be replace');

            runInAction((): void => {
                matomoActive.set(true);
            });

            window.addEventListener('orientationchange', updateOrientation);
        } catch (error) {
            Logger.error(error);
        }
    } else {
        disableTracking();
    }
}

export function trackEvent(data: SupportedTrackingEvents): void {
    pushEventData(['trackEvent', data.category, data.action, data.name, data.value].filter(notNull));

    if (data.category === 'Full Screen') {
        setFullscreenDimension(data.action === 'enter');
    }
}

function updateOrientation(): void {
    const orientation: string = window.matchMedia('(orientation: portrait)').matches ? 'portrait' : 'landscape';
    setCustomDimension(MATOMO_DIMENSION_ORIENTATION, orientation);
}

export function setLayoutDimension(isDarkmode: boolean): void {
    setCustomDimension(MATOMO_LAYOUT_DIMENSION, isDarkmode ? 'darkmode' : 'lightmode');
}

export function setFullscreenDimension(isFullscreen: boolean): void {
    setCustomDimension(MATOMO_FULLSCREEN_DIMENSION, isFullscreen ? 'full screen' : 'default');
}

const LoginDimensionFallbackValue: string = 'no_login';

export function updateLoginDimension(name?: string): void {
    if (isNotNullOrEmpty(name)) {
        setCustomDimension(MATOMO_DIMENSION_LOGIN, name);
        setCustomDimension(MATOMO_VISIT_DIMENSION_LOGIN, name);
    } else {
        setCustomDimension(MATOMO_DIMENSION_LOGIN, LoginDimensionFallbackValue);
        setCustomDimension(MATOMO_VISIT_DIMENSION_LOGIN, LoginDimensionFallbackValue);
    }
    pushEventData(['trackPageView']); // need to be called to track changed/new customDimension values
}

/**
 * Sets custom url to current url + /view if env MATOMO_TRACK_LAYER_CHANGE is set
 * @param view
 */
export function updateCustomUrl(view?: string): void {
    let customUrl: string = location.href;

    if (appInfo?.app_caching?.active === true) {
        customUrl = appInfo.initialization_url;
    }

    customUrl = stripHash(customUrl);

    if (!MATOMO_TRACK_LAYER_CHANGE || isNullOrEmpty(view)) {
        pushEventData(['setCustomUrl', customUrl]);
    } else {
        pushEventData(['setCustomUrl', customUrl + '/' + view]);
        pushEventData(['setDocumentTitle', view]);
    }

    pushEventData(['trackPageView']);
}

export function trackSearch(term: string, category: string, results: number): void {
    if (isNotNullOrEmpty(term)) {
        pushEventData(['trackSiteSearch', term, category, results]);
    }
}

function setDownloadExtensions(values: Set<string>): void {
    pushEventData(['setDownloadExtensions', Array.from(values).join('|')]);
}

export function trackTaxSetting(
    isGlobal: boolean,
    countryCode: string,
    visibility: boolean | undefined
): void {
    trackEvent({
        category: isGlobal ? 'Global settings' : 'Article list settings',
        action: 'taxes',
        name: countryCode === 'ZZ' ? 'custom' : countryCode,
    });

    if (visibility != null) {
        trackEvent({
            category: isGlobal ? 'Global settings' : 'Article list settings',
            action: 'taxes',
            name: visibility ? 'Price-with-VAT' : 'Price-without-VAT'
        });
    }
}

export function trackAddArticle(
    sourceType: 'catalog-article' | 'list-article',
    manufacturerId: string
): void {
    trackEvent({
        category: 'Article',
        action: 'add_manufacturer_to_list',
        name: manufacturerId,
    });

    trackEvent({
        category: 'Article',
        action: 'add_to_list',
        name: sourceType
    });
}

export function trackPIMCategory(category: string, isCustomCategory: boolean): void {
    trackEvent({
        category: isCustomCategory ? 'PIM Individual' : 'PIM',
        action: 'open',
        name: category
    });
}