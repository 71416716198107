import type { BaseApp } from '@egr/xbox/base-app/App';

import sortBy from 'lodash/sortBy';

import { GatekeeperManager } from '@egr/xbox/base-app/managers/GatekeeperManager';
import { DATA_LANGUAGES } from '@egr/xbox/base-app/managers/SessionManager';

import { unique } from '@easterngraphics/wcf/modules/utils/array';
import { isNotNullOrEmpty } from '@easterngraphics/wcf/modules/utils/string';

export class SessionManager extends GatekeeperManager {
    public constructor(
        app: BaseApp,
        protected languages: Array<string>
    ) {
        super(app);
    }

    public override async setLanguage(primaryLanguage: string | null = null): Promise<void> {
        if (isNotNullOrEmpty(primaryLanguage)) {
            this.updateState({ dataLanguage: primaryLanguage });
        }
        const languages: Array<string> = this.getSortedDataLanguages(primaryLanguage);
        await this.app.eaiwsSession.catalog.setLanguages(languages);
        await this.app.eaiwsSession.basket.setLanguages(languages);
        this.onChange.trigger();
    }

    public override getSortedDataLanguages(primaryLanguage: string | null = null): Array<string> {
        let languages: Array<string> = this.languages;
        if (primaryLanguage != null) {
            languages = unique([primaryLanguage, ...languages, ...DATA_LANGUAGES]);
        }
        return sortBy(
            languages,
            (value: string): number => {
                const pos: number = languages.indexOf(value);
                if (pos === -1) {
                    return languages.length + 1;
                }

                return pos;
            }
        );
    }
}