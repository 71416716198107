import type { FileFormatClass } from './FileFormats';
import type { ProjectText } from '@easterngraphics/wcf/modules/eaiws/project';

import i18next from 'i18next';

import { notNull } from './Types';
import { parseFilename } from './string/parseFilename';

import { isNullOrEmpty, isNotNullOrEmpty } from '@easterngraphics/wcf/modules/utils/string';

export { parseFilename };

const LocaleAliasMap: Map<string, string> = new Map<string, string>([
    ['en', 'en_US'], ['de', 'de_DE'], ['fr', 'fr_FR'], ['nl', 'nl_NL'], ['cs', 'cs_CZ'],
    ['da', 'da_DK'], ['it', 'it_IT'], ['no', 'nn_NO'], ['pl', 'pl_PL'], ['pt', 'pt_PT'],
    ['ru', 'ru_RU'], ['ro', 'ro_RO'], ['es', 'es_ES'], ['sv', 'sv_SE'], ['tr', 'tr_TR'],
    // fall 2023
    ['bg', 'bg_BG'], ['fi', 'fi_FI'], ['ga', 'ga_IE'], ['hu', 'hu_HU'], ['lv', 'lv_LV'],
    ['sk', 'sk_SK'],
    // spring 2024
    ['af', 'af_ZA'], ['ca', 'ca_ES'], ['el', 'el_GR'], ['et', 'et_EE'], ['hr', 'hr_HR'],
    ['id', 'id_ID'], ['ja', 'ja_JP'], ['ko', 'ko_KR'], ['lt', 'lt_LT'], ['mk', 'mk_MK'],
    ['nb', 'nb_NO'], ['sl', 'sl_SL'], ['sq', 'sq_AL'], ['sr', 'sr_RS'], ['uk', 'uk_UA'],
    ['vi', 'vi_VN'], ['zh', 'zh_CN']
]);

export const LanguageMap: Map<string, string> = new Map<string, string>([
    ['en', 'English'], ['de', 'Deutsch'], ['fr', 'Français'], ['nl', 'Nederlands'], ['cs', 'Český'],
    ['da', 'Dansk'], ['it', 'Italiano'], ['pl', 'Polski'], ['pt', 'Português'], ['nb', 'Norsk'],
    ['ru', 'Русский'], ['ro', 'Română'], ['es', 'Español'], ['sv', 'Svenska'], ['tr', 'Türkçe'],
    // fall 2023
    ['bg', 'български'], ['fi', 'Suomi'], ['ga', 'Gaeilge'], ['hu', 'Magyar'], ['lv', 'Latviešu'],
    ['sk', 'Slovenčina'],
    // spring 2024
    ['af', 'Afrikaans'], ['ca', 'Català'], ['el', 'Ελληνικά'], ['et', 'Eesti keel'], ['hr', 'Hrvatski'],
    ['id', 'Bahasa Indonesia'], ['ja', '日本語'], ['ko', '한국인'], ['lt', 'Lietuvių'], ['mk', 'македонски'],
    ['sl', 'Slovenščina'], ['sq', 'Shqiptare'], ['sr', 'Српски'], ['uk', 'українська'],
    ['vi', 'Tiếng Việt'], ['zh', '中國人']
]);

export function getLocale(language: string | undefined): string {
    return LocaleAliasMap.get(language ?? 'en') ?? 'en_US';
}

/**
 * Do not change order of `en,de,fr,nl,cs,da,it,no,pl,pt,ru,ro,es,sv,tr`
 *
 * Source: https://www.localeplanet.com/icu/
 */
export const DefaultDataLanguages: Array<string> = [
    'de', 'en', 'fr', 'nl', 'cs', 'da', 'it', 'nb',
    'pl', 'pt', 'ru', 'ro', 'es', 'sv', 'tr', // 'no' removed with spring 2024 release
    // new in fall 2023
    'bg', 'fi', 'ga', 'hu', 'lv','sk',
    // new in spring 2024
    'af', 'ca', 'el', 'et', 'hr', 'id', 'ja', 'ko',
    'lt', 'mk', 'sl', 'sq', 'sr', 'uk', 'vi', 'zh',
];

export function validDataLanguage(value: string): boolean {
    return DefaultDataLanguages.includes(value);
}

interface AsciiFilenameOptions {
    replaceCharacter?: string;
    extension?: string;
    extractExtension?: boolean;
    allowEmptyFilenames?: boolean;
    requireExtension?: boolean;
}

const MatchExtension: RegExp = new RegExp(/^([A-Za-z0-9]+)$/);
export function getAsciiFilename(value: string, options: AsciiFilenameOptions = {}): string {
    const { replaceCharacter = '_'} = options;

    let filename: string = value;
    let extension: string | undefined;

    if (options.extractExtension) {
        [filename, extension] = parseFilename(filename);
    }

    filename = convertUmlauts(filename).replace(/[^\w\-]/g, replaceCharacter);

    if (extension == null && options.extension != null) {
        extension = options.extension;
    }

    if (options.allowEmptyFilenames !== true && isNullOrEmpty(filename)) {
        throw new Error('invalid filename');
    }

    if (isNotNullOrEmpty(extension)) {
        if (!MatchExtension.test(extension)) {
            throw new Error('invalid extension');
        }

        return `${filename}.${extension}`;
    }

    if (options.requireExtension) {
        throw new Error('missing extension');
    }

    return filename;
}

const umlautReplacement = new Map([
    ['ä', 'ae'],
    ['Ä', 'Ae'],
    ['Ü', 'Ue'],
    ['ü', 'ue'],
    ['ö', 'oe'],
    ['Ö', 'Oe'],
    ['ß', 'ss'],
    ['ç', 'c'],
    ['ñ', 'n'],
    ['á', 'a'],
    ['à', 'a'],
    ['â', 'a'],
    ['Á', 'A'],
    ['À', 'A'],
    ['Â', 'A'],
    ['é', 'e'],
    ['è', 'e'],
    ['ê', 'e'],
    ['ë', 'e'],
    ['É', 'E'],
    ['È', 'E'],
    ['Ê', 'E'],
    ['Ë', 'E'],
    ['í', 'i'],
    ['ì', 'i'],
    ['î', 'i'],
    ['ï', 'i'],
    ['Í', 'I'],
    ['Ì', 'I'],
    ['Î', 'I'],
    ['Ï', 'I'],
    ['ó', 'o'],
    ['ò', 'o'],
    ['ô', 'o'],
    ['Ó', 'O'],
    ['Ò', 'O'],
    ['Ô', 'O'],
    ['ú', 'u'],
    ['ù', 'u'],
    ['û', 'u'],
    ['Ú', 'U'],
    ['Ù', 'U'],
    ['Û', 'U'],
]);

function convertUmlauts(input: string): string {
    let output: string = '';

    for (const char of input) {
        output += umlautReplacement.get(char) ?? char;
    }

    return output;
}

export function isDataUri(value: string | undefined | null): boolean {
    if (isNullOrEmpty(value)) {
        return false;
    }
    return /^\s*data:([a-z]+\/[a-z\+]+(;[a-z\-]+\=[a-z\-]+)?)?(;base64)?,[a-z0-9\!\$\&\'\,\(\)\*\+\,\;\=\-\.\_\~\:\@\/\?\%\s]*\s*$/i.test(value);
}

export function isUrl(value: string | undefined): value is string {
    return isNotNullOrEmpty(value) && value.startsWith('http');
}

export function getFileExtension(value: string | undefined): string {
    if (isNotNullOrEmpty(value)) {
        const splittedName: Array<string> = value.split('.');
        if (splittedName.length > 0) {
            return splittedName[splittedName.length - 1];
        }
    }
    return '';
}

export function getFilename(baseName: string, format: FileFormatClass): string {
    return getAsciiFilename(baseName, { extension: format.extension});
}

export function getProjectText(projectTexts: Array<ProjectText>, type: 'HeaderText' | 'FooterText', language: string): string | undefined {
    for (const projectText of projectTexts) {
        if (projectText.type === type && projectText.texts?.length) {
            const formattedTexts = projectText.texts;
            const formattedTextSpecific = formattedTexts.find(
                (value) => {
                    return value.language === language;
                }
            );

            if (formattedTextSpecific) {
                return formattedTextSpecific.value;
            }

            const formattedTextFallback = formattedTexts.find(
                (value) => {
                    return (
                        isNullOrEmpty(value.language) || value.language === 'und'
                    );
                }
            );

            return formattedTextFallback?.value;
        }
    }

    return;
}
export function textFromIML(value: string | undefined): string | undefined {
    if (value == null) {
        return;
    }

    try {
        const lines: Array<string> = [];
        const match = value.match(/@p{([^}]*)}/g);

        if (match == null) {
            return;
        }

        for (const part of match) {
            lines.push(part.replace(/@p{([^}]*)}/, '$1'));
        }

        return lines.join('\n');
    } catch (e) {
        /* */
    }

    return;
}

/* Code copied from wbk */
type EaiwsJsonTag = 'indent' | 'ol' | 'ul' | 'li' | 'p' | 'b' | 'i' | 'u' | 'br';
type EaiwsJsonContent = Array<string | EaiwsJsonElement> | null;
type EaiwsJsonElement = [EaiwsJsonTag, null, EaiwsJsonContent] | [EaiwsJsonTag];
type EaiwsJsonText = ['iml', null, EaiwsJsonContent];

export function plainTextToEaiwsJson(text: string): EaiwsJsonText {
    const lines = text.trim().split('\n');

    const paragraphContent: EaiwsJsonContent = [];

    for (let i = 0; i < lines.length; ++i) {
        let line = lines[i];

        if (isNotNullOrEmpty(line)) {
            //EAIWS will remove consecutive spaces, replace every second space with a 'non breaking space' to avoid this
            line = line.replace(/ {2}/g, ' \u00A0');
            paragraphContent.push(line);
        }

        if (i < lines.length - 1) {
            paragraphContent.push(['br']);
        }
    }

    return [
        'iml',
        null,
        [
            [
                'p',
                null,
                paragraphContent
            ]
        ]
    ];
}

export function minMax(min: number | string | undefined, max: number | string | undefined): string {
    const values: [ string | null, string | null ] = [
        min != null ? `${i18next.t('min', { context:  'abbreviation for minimum' })}: ${min}` : null,
        max != null ? `${i18next.t('max', { context:  'abbreviation for maximum' })}: ${max}` : null
    ];

    return values.filter(notNull).join(' - ');

}