import { GATEKEEPER_ID } from './Constants';

import { AppName } from '@egr/xbox/utils/Constants';
import { safelyParseJSON } from '@egr/xbox/utils/Json';
import { getEnvArray, getEnvVar, getEnvBoolean } from '@egr/xbox/utils/ReactScriptHelper';

import { isNotNullOrEmpty } from '@easterngraphics/wcf/modules/utils/string';

/**
 * We cannot pass a default value to the original {@link getEnvArray},
 * which we could use to pass a value from the session storage if this property
 * is not set in the URL, so we need a separate function.
 * @param key key of the param
 * @param delimiter delimiter (same default as in {@link getEnvArray})
 * @returns actual URL parameter or value from session storage
 */
function getEnvArrayCustom(key: string, delimiter: string = ','): Array<string> {
    if(!getEnvBoolean('apcs') || getEnvArray(key, delimiter).length > 0){
        return getEnvArray(key, delimiter);
    }

    const value = decodeURIComponent(getDefault<string>(key, ''));
    return value.split(delimiter).map((v: string): string => v.trim()).filter(isNotNullOrEmpty);
}

/**
 * If apcs is true and an article is set by {@link LocationTrackingManager}
 * in the session storage, these values will be used.
 * @param key key of the param
 * @param fallback fallback if not set in the session storage
 * @returns session storage entry or fallback
 */
function getDefault<T>(key: string, fallback: T): T {
    if(!getEnvBoolean('apcs')){
        return fallback;
    }

    const paramsString = sessionStorage.getItem(GATEKEEPER_ID + '-apcs_last_article_params');
    if(!paramsString) return fallback;

    const params: Array<Array<string>> = safelyParseJSON(paramsString)!;

    for(const entry of params){
        if(entry[0] === key) return entry[1] as T;
    }

    return fallback;
}

export const ExperimentalFeatures: boolean = getEnvBoolean('ENABLE_EXPERIMENTAL_FEATURES', false);
export const DisableCloud: boolean = getEnvBoolean('DISABLE_CLOUD', false);
export const EaiwsStartup: string = getEnvVar('EAIWS_STARTUP', '');
export const AdminPanel: boolean = getEnvBoolean('ap', false);

export const HookUrl: string = getEnvVar('HOOK_URL', '');
export const CustomFields: string = getEnvVar('customFields', '');
export const ManufacturerOrCatalog: string = getEnvVar('moc', getDefault<string>('moc', ''));
export const BaseArticleNumber: string = getEnvVar('ban', getDefault<string>('ban', ''));
export const SeriesId: string = getEnvVar('sid', getDefault<string>('sid', ''));
export const PecSearchString: string = getEnvVar('pss', getDefault<string>('pss', ''));
export const ObxUrl: string = getEnvVar('obx', getDefault<string>('obx', ''));
export const OfmlVariantCode: string = getEnvVar('ovc', getDefault<string>('ovc', ''));

export const CatalogRootPath: Array<string> = getEnvArrayCustom('crp');
export const CatalogEntryPath: Array<string> = getEnvArrayCustom('cep');

export const ForceCatalogRoot: boolean = getEnvBoolean('fcr', false);
export const productName: string = AppName ?? '';

export const ArticleNoInSubInfo: boolean = true;
export const SeriesInSubInfo: boolean = true;
export const ManufacturerInSubInfo: boolean = true;
export const showArticleName: boolean = true;
export const EnableMetatypeProperties: boolean = true;

export const IsPlanner: boolean = navigator.userAgent.toLocaleLowerCase().indexOf('w-cclient') !== -1 &&
    navigator.userAgent.toLocaleLowerCase().indexOf('p-pl-x') !== -1;
export const IsRevit: boolean = navigator.userAgent.toLocaleLowerCase().indexOf('p_cat_rvt') !== -1;

export const LanguageMapping: Record<string, { name: string, locale: string}> = {
    en: {name: 'English', locale: 'en_US'},
    de: {name: 'Deutsch', locale: 'de_DE'},
    da: {name: 'Dansk', locale: 'da_DK'},
    es: {name: 'Español', locale: 'es_ES'},
    fr: {name: 'Français', locale: 'fr_FR'},
    it: {name: 'Italiano', locale: 'it_IT'},
    nl: {name: 'Nederlands', locale: 'nl_NL'},
    pl: {name: 'Polskie', locale: 'pl_PL'},
    pt: {name: 'Português', locale: 'pt_PT'},
    ro: {name: 'Română', locale: 'ro_RO'},
    tr: {name: 'Türkçe', locale: 'tr_TR'},
    sv: {name: 'Svenska', locale: 'sv_SE'},
    cs: {name: 'Český', locale: 'cs_CZ'},
};