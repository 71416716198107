import type { AppProps } from './App';
import type { IObservableValue} from 'mobx';

import { observable, runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { elementHasSize } from '@egr/xbox/egr-gui-utils/MiscHelper';
import { getEnvVar, PUBLIC_URL } from '@egr/xbox/utils/ReactScriptHelper';
import { captureException } from '@egr/xbox/utils/errors/Error';

import { loadStyleSheet } from '@easterngraphics/wcf/modules/utils/dom';

const BUILD_COMMIT: string = getEnvVar('BUILD_COMMIT', 'develop');

const appComponent: IObservableValue<JSX.Element | null> = observable.box(null);

interface AppLoaderProps extends AppProps {
    loadIframeResizer: boolean;
}

export const AppLoader: React.FC<AppLoaderProps> = observer((props: AppLoaderProps): JSX.Element | null => {
    React.useEffect(
        (): void => {
            void (async (): Promise<void> => {
                const { loadIframeResizer, ...appProps } = props;

                try {
                    if (loadIframeResizer) {
                        const script: HTMLScriptElement = document.createElement('script');
                        script.src = 'static/js/iframeResizer.contentWindow.min.js';
                        document.body.appendChild(script);
                    }
                } catch (e) {
                    captureException(e, 'debug');
                }

                void Promise.all([
                    loadStyleSheet(`${PUBLIC_URL}/w-cf/styles/core.css?v=${BUILD_COMMIT}`),
                    loadStyleSheet(`${PUBLIC_URL}/w-cf/styles/cf.css?v=${BUILD_COMMIT}`)
                ]);

                const { RealApp } = await import('./renderApp');

                await elementHasSize(document.body);

                runInAction(() => {
                    appComponent.set(React.createElement(RealApp, appProps));
                });
            })();
        },
        [] // call only once
    );

    return appComponent.get();
});