import bind from 'bind-decorator';
import { action, observable, reaction, computed, makeObservable } from 'mobx';
import { useState, useEffect } from 'react';

import { mediaQueryMatch } from '@egr/xbox/utils/UserAgent';

export interface BreakpointObserverInfo {
    smallLayout: boolean;
    phoneLandscape: boolean;
}

interface Breakpoints {
    sm: number;
    md: number;
}

class BreakpointObserverClass {
    constructor() {
        makeObservable(this);
        this.update = this.update.bind(this);
    }

    @computed
    public get isPhone(): boolean {
        return (
            this.info.phoneLandscape || this.info.smallLayout
        );
    }

    @computed
    public get smallLayout(): boolean {
        return this.info.smallLayout;
    }

    @computed
    public get phoneLandscape(): boolean {
        return this.info.phoneLandscape;
    }

    @observable
    public info: BreakpointObserverInfo = {
        smallLayout: false,
        phoneLandscape: false,
    };

    private breakpoints: Breakpoints | undefined;

    @bind
    public setBreakpoints(newBreakpoints: Breakpoints): void {
        window.removeEventListener('resize', this.update);
        this.breakpoints = newBreakpoints;
        this.update();
        window.addEventListener('resize', this.update);
    }

    @action
    private update(): void {
        if (this.breakpoints == null) {
            return;
        }

        this.info = {
            smallLayout: mediaQueryMatch(`(max-width:${this.breakpoints.sm - .05}px)`),
            phoneLandscape: mediaQueryMatch(`(max-width:${this.breakpoints.md - .05}px) and (max-height:${this.breakpoints.sm - .05}px) and (orientation:landscape)`)
        };
    }
}

export const breakpointObserver: BreakpointObserverClass = new BreakpointObserverClass();

export const useBreakpointInfo: () => BreakpointObserverInfo = () => {
    const [info, setInfo] = useState(breakpointObserver.info);

    const update: (info: BreakpointObserverInfo) => void = (newInfo: BreakpointObserverInfo) => {
        setInfo(newInfo);
    };

    useEffect(
        () => {
            return reaction(() => breakpointObserver.info, update);
        },
        [
            breakpointObserver
        ]
    );

    return info ?? { smallLayout: false, phoneLandscape: false };
};