import type App from './App';
import type { AppProps } from './App';
import type { UiManagerState } from './managers/UiManager';

import i18next from 'i18next';
import merge from 'lodash/merge';
import { configure } from 'mobx';
import * as React from 'react';
import { createRoot } from 'react-dom/client';

import { AppLoader } from './AppLoader';
import { ALLOW_CUSTOM_POOL, GATEKEEPER_ID } from './Constants';
import { DisableCloud, EaiwsStartup, LanguageMapping } from './DefaultValues';
import { SessionManager as PconUiSessionManager } from './managers/SessionManager';
import { loadUiSettings } from './utils/UiSettings';

import { addGatekeeperIdHook } from '@egr/xbox/base-app/hooks/GatekeeperId';
import { initInstance, USER_LANGUAGES } from '@egr/xbox/base-app/i18n';
import { GatekeeperManager } from '@egr/xbox/base-app/managers/GatekeeperManager';
import { StaticEaiwsManager } from '@egr/xbox/base-app/managers/StaticEaiwsServer';
import { EAIWS_SERVER } from '@egr/xbox/utils/Constants';
import { printTimer } from '@egr/xbox/utils/Debug';
import { safelyParseJSON } from '@egr/xbox/utils/Json';
import { getEnvBoolean, PUBLIC_URL } from '@egr/xbox/utils/ReactScriptHelper';
import { setupSentry, updateTag, enableSentry } from '@egr/xbox/utils/errors/Error';

import { wcfConfig } from '@easterngraphics/wcf/modules/utils';
import { isNotNullOrEmpty } from '@easterngraphics/wcf/modules/utils/string';

printTimer('Start', 'START');
printTimer('BeforeConfiguration', 'START');

configure({
    enforceActions: 'observed',
    computedRequiresReaction: false,
    reactionRequiresObservable: false,
    observableRequiresReaction: false,
    disableErrorBoundaries: false
});

enableSentry(true);
setupSentry();

function getEvaluationLicensedInformation(): boolean {
    const allowedDomains: Array<RegExp> = [
        /(https?:\/\/(.+?\.)?pcon\.eu)/,
        /(https?:\/\/(.+?\.)?easterngraphics\.com)/,
        /(https?:\/\/(.+?\.)?pcon-solutions\.com)/
    ];
    return allowedDomains.some((value) => { return value.test(EAIWS_SERVER ?? ''); });
}

async function run(): Promise<void> {
    wcfConfig.dataPath = PUBLIC_URL + '/w-cf/data/';

    let gatekeeperId: string = DisableCloud ? '' : GATEKEEPER_ID;
    if (!ALLOW_CUSTOM_POOL && gatekeeperId.indexOf(':') !== -1) {
        gatekeeperId = gatekeeperId.split(':')[0];
    }

    const props = await getProps(gatekeeperId);

    const i18nextInstance = await initInstance(
        USER_LANGUAGES,
        props.uiManagerState?.settings.general.lang
    );

    if (isNotNullOrEmpty(props.uiManagerState?.settings.general.translationsImportUrl)) {
        const languages = isNotNullOrEmpty(i18nextInstance.language) ? [i18nextInstance.language, 'en'] : ['en'];
        for (const language of languages) {
            try {
                const request = await fetch( `${props.uiManagerState.settings.general.translationsImportUrl}/${language}.json`);
                const content = await request.json();
                i18next.addResourceBundle(language, 'translation', content);
            } catch (e) {
                console.error(e);
            }
        }
    }

    const app: JSX.Element = (
        <AppLoader
            {...props}
            loadIframeResizer={Boolean(
                !DisableCloud &&
                props.uiManagerState?.settings?.general.enableIframeResizer &&
                window.self !== window.top
            )}
        />
    );

    const rootElement = document.getElementById('root');

    if (rootElement == null) {
        throw new Error('root element not found');
    }

    const root = createRoot(rootElement);

    root.render(app);
}

async function getProps(gatekeeperId: string): Promise<AppProps> {
    const isEvaluationLicensed = getEvaluationLicensedInformation();

    updateTag('gatekeeperId', gatekeeperId);

    if (isNotNullOrEmpty(EAIWS_SERVER) && isNotNullOrEmpty(EaiwsStartup) && !isEvaluationLicensed) {
        addGatekeeperIdHook(async () => { return 'null'; /* will cause intended "unlicensed error" */ });
        return {
            gatekeeperId,
            getSessionManager: (app: App) => new GatekeeperManager(app),
        };
    } else {
        const uiManagerState = await loadUiSettings(gatekeeperId);
        const sessionStorageConfig: UiManagerState | null = getSessionStorageStateOverride() as UiManagerState | null;
        if (getEnvBoolean('apcs', false) && sessionStorageConfig && uiManagerState) {
            uiManagerState.settings = sessionStorageConfig.settings;
            uiManagerState.visibility = sessionStorageConfig.visibility;
            uiManagerState.styles = sessionStorageConfig.styles;
            uiManagerState.theme = sessionStorageConfig.theme;
            //merging here to keep the default iframeparents localhost, and egr domains
            uiManagerState.permissions = merge(
                uiManagerState.permissions,
                sessionStorageConfig.permissions
            );
        }
        if (!uiManagerState) {
            addGatekeeperIdHook(async () => { return 'null'; /* will cause intended "unlicensed error" */ });
            return {
                gatekeeperId,
                getSessionManager: (app: App) => new GatekeeperManager(app),
            };
        } else if (isNotNullOrEmpty(EAIWS_SERVER) && isNotNullOrEmpty(EaiwsStartup)) {
            return {
                gatekeeperId,
                getSessionManager: (app: App) => new StaticEaiwsManager(app),
                uiManagerState
            };
        } else {
            addGatekeeperIdHook(async () => { return gatekeeperId; });
            return {
                gatekeeperId,
                getSessionManager: (app: App) => new PconUiSessionManager(app, Object.keys(LanguageMapping)),
                uiManagerState
            };
        }
    }

}

const getSessionStorageStateOverride = () => {
    const sessionStorageKey: string = GATEKEEPER_ID + '-customSettings';
    try {
        const uiManagerState: string | null = sessionStorage.getItem(sessionStorageKey);
        return isNotNullOrEmpty(uiManagerState) ? (safelyParseJSON(uiManagerState)!) : null;
    } catch (error) {
        return null;
    }
};

void run();